import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/common/logo.png";

export default function Footer() {
  // 다국어 기능
  const { t } = useTranslation();

  useEffect(() => {
    // 스크롤 위로
    let scrollUp = document.querySelectorAll(".btn_scrollTop, .logo");
    scrollUp.forEach((el) => {
      el.addEventListener("click", function () {
        window.scrollTo(0, 0);
      });
    });
  });

  return (
    <footer className="footer">
      <div className="inner d-flex flex-js flex-ac">
        <div className="text_box">
          <p className="comp_name">{t("company")}</p>
          <p className="information">02-564-0780</p>
          <p className="information">ad@goodnetwork.biz</p>
          <p className="copy">
            Copyright ⓒ 2024. WIZCLASS. All Rights Reserved.
          </p>
        </div>
        <div className="foot_logo">
          <img src={logo} alt="GNW 로고 이미지" />
        </div>
      </div>
      <button className="btn_scrollTop">
        <span className="sr_only">위로 가기</span>
      </button>
    </footer>
  );
}
