import { Trans, useTranslation } from "react-i18next";
import vision from "../../assets/images/img_vision_right.png";

export default function About() {
  const { t } = useTranslation();
  return (
    <section id="about" className="section">
      {/* about - vision */}
      <article className="vision">
        <div className="inner">
          <div className="cont_inner d-flex flex-ac">
            <div className="text_wrap">
              <h2 className="main_title" data-aos="fade">
                About GNW Foundation
              </h2>
              <p
                className="main_desc"
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
              >
                <Trans i18nKey="vision" components={{ 1: <br /> }} />
              </p>
            </div>
            <div className="img_wrap" data-aos="fade-left">
              <img src={vision} alt="GNW" />
            </div>
          </div>
        </div>
      </article>
      {/* about - NFT */}
      <article className="nft">
        <div className="nft_cont cont01 d-flex">
          <div className="text_wrap d-flex">
            {/* <h2 className="main_title" data-aos="fade">NFT + {t('aboutTit')}</h2> */}
            <h2 className="main_title" data-aos="fade">
              {t("about1")}
            </h2>
            <ul
              className="main_desc"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <li>{t("aboutText1-1")}</li>
              <li>{t("aboutText1-2")}</li>
              <li>{t("aboutText1-3")}</li>
            </ul>
          </div>
          <div className="hidden_box">
            <div className="img_wrap" data-aos="fade-left"></div>
          </div>
        </div>
        <div className="nft_cont cont02 d-flex">
          <div className="text_wrap d-flex">
            <h2 className="main_title" data-aos="fade">
              {t("about2")}
            </h2>
            <ul
              className="main_desc"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <li>{t("aboutText2-1")}</li>
              <li>{t("aboutText2-2")}</li>
            </ul>
          </div>
          <div className="hidden_box">
            <div className="img_wrap" data-aos="fade-right"></div>
          </div>
        </div>
      </article>
    </section>
  );
}
