import partner1 from "../../assets/images/partner_1.png";
import partner2 from "../../assets/images/partner_2.png";
import partner3 from "../../assets/images/partner_3.png";
import partner4 from "../../assets/images/partner_4.png";
import partner5 from "../../assets/images/partner_5.png";
import partner6 from "../../assets/images/partner_6.png";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";

export default function Partner() {
  const { t } = useTranslation();
  return (
    <section id="partners" className="section">
      <div className="inner">
        <h2 className="main_title" data-aos="fade-up">
          PARTNERS
        </h2>
        <span className="partners_sub">{t("partnerSub")}</span>

        <div className="partners" data-aos="fade-up" data-aos-delay="150">
          <div className="partners_box">
            <Marquee speed={100}>
              <div className="partners_list">
                <img src={partner1} alt="파트너" />
              </div>
              <div className="partners_list">
                <img src={partner2} alt="파트너" />
              </div>
              <div className="partners_list">
                <img src={partner3} alt="파트너" />
              </div>
              <div className="partners_list">
                <img src={partner4} alt="파트너" />
              </div>
              <div className="partners_list">
                <img src={partner5} alt="파트너" />
              </div>
              <div className="partners_list">
                <img src={partner6} alt="파트너" />
              </div>
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
}
