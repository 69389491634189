import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// js
import AOS from 'aos';
// css
import 'aos/dist/aos.css';

export default function AfterEffect() {
  const {pathname} = useLocation();
  
  useEffect(() => {
    // 페이지 이동할 때 항상 스크롤 최상단으로
    window.scrollTo({top:0, left:0, behavior: 'auto'});

    // aos 초기화
    AOS.init({
      duration: 700,
    }); 

    // header black 처리
    if(pathname === "/" || pathname === "/adm" || pathname === "/adm/") {
      document.querySelector('body').classList.remove('black');
    } else {
      document.querySelector('body').classList.add('black');
    }
  });

  return null;
};