import { Trans, useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

export default function Solution() {
  const { t } = useTranslation();
  return (
    <section id="solution" className="section">
      {/* solution - table */}
      {
        <article className="table">
          <div className="inner">
            <div className="grid_box">
              <div
                className="box evm"
                data-aos="zoom-in-up"
                data-aos-duration="900"
              >
                <span className="c_gray small">{t("solution1_sub")}</span>
                <strong>{t("solution1_title")}</strong>
              </div>
              <div
                className="box tps"
                data-aos="zoom-in-up"
                data-aos-duration="900"
              >
                <span className="c_gray small">{t("solution2_sub")}</span>
                <strong>{t("solution2_title")}</strong>
              </div>
              <div
                className="box block"
                data-aos="zoom-in-up"
                data-aos-duration="900"
              >
                <span className="c_gray small">{t("solution3_sub")}</span>
                <strong>{t("solution3_title")}</strong>
              </div>
              <div
                className="box wemix"
                data-aos="zoom-in-up"
                data-aos-duration="900"
              >
                <span className="c_gray small">{t("solution4_sub")}</span>
                <strong>{t("solution4_title")}</strong>
              </div>
              <div
                className="box raft"
                data-aos="zoom-in-up"
                data-aos-duration="900"
                data-aos-offset="-200"
              >
                <span className="c_gray small">{t("solution5_sub")}</span>
                <strong>{t("solution5_title")}</strong>
              </div>
              <div
                className="box partnership"
                data-aos="zoom-in-up"
                data-aos-duration="900"
                data-aos-offset="-200"
              >
                <span className="c_gray small">{t("solution6_sub")}</span>
                <strong>{t("solution6_title")}</strong>
              </div>
              <div
                className="box copyright"
                data-aos="zoom-in-up"
                data-aos-duration="900"
                data-aos-offset="-200"
              >
                <span className="c_gray small">{t("solution7_sub")}</span>
                <strong>{t("solution7_title")}</strong>
              </div>
              <div
                className="box community"
                data-aos="zoom-in-up"
                data-aos-duration="900"
                data-aos-offset="-200"
              >
                <span className="c_gray small">{t("solution8_sub")}</span>
                <strong>{t("solution8_title")}</strong>
              </div>
            </div>
          </div>
        </article>
      }
      {/* solution - loadMap */}
      <article className="load_map bg_gradation">
        <div className="text_wrap">
          <div className="inner">
            <h2 className="main_title" data-aos="fade">
              GNW Road Map
            </h2>

            <p
              className="main_desc"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <Trans
                i18nKey="loadMap"
                components={{
                  1: <br />,
                }}
              />
            </p>
          </div>
        </div>
        <div className="load_map_box pc" data-aos="fade">
          <div className="inner d-flex flex-jc">
            <Swiper
              slidesPerView={4}
              navigation={true}
              modules={[Navigation]}
              /* breakpoints={{
            768: {
              slidesPerView: 1,
              navigation: false,
            },
          }} */
            >
              <SwiperSlide>
                <div
                  className="box"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                >
                  <div className="img first"></div>
                  <div className="text">
                    <strong className="font_mont">3rd Quarter 2024</strong>
                    <ul>
                      <li>{t("2024-3Q-1")}</li>
                      <li>
                        <Trans
                          i18nKey="2024-3Q-2"
                          components={{
                            1: <br className="only_mo" />,
                            2: <br />,
                          }}
                        />
                      </li>
                      <li>{t("2024-3Q-3")}</li>
                      <li>{t("2024-3Q-4")}</li>
                      <li>{t("2024-3Q-5")}</li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="box"
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="1000"
                >
                  <div className="img second"></div>
                  <div className="text">
                    <strong className="font_mont">4th Quarter 2024</strong>
                    <ul>
                      <li>{t("2024-4Q-1")}</li>
                      <li>{t("2024-4Q-2")}</li>
                      <li>{t("2024-4Q-3")}</li>
                      <li>{t("2024-4Q-4")}</li>
                      <li>{t("2024-4Q-5")}</li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="box"
                  data-aos="fade-right"
                  data-aos-delay="500"
                  data-aos-duration="1000"
                >
                  <div className="img third"></div>
                  <div className="text">
                    <strong className="font_mont">1st Quarter 2025</strong>
                    <ul>
                      <li>{t("2025-1Q-1")}</li>
                      <li>{t("2025-1Q-2")}</li>
                      <li>{t("2025-1Q-3")}</li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="box"
                  data-aos="fade-right"
                  data-aos-delay="650"
                  data-aos-duration="1000"
                >
                  <div className="img fourth"></div>
                  <div className="text">
                    <strong className="font_mont">2nd Quarter 2025</strong>
                    <ul>
                      <li>{t("2025-2Q-1")}</li>
                      <li>{t("2025-2Q-2")}</li>
                      <li>{t("2025-2Q-3")}</li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="box"
                  data-aos="fade-right"
                  data-aos-delay="650"
                  data-aos-duration="1000"
                >
                  <div className="img first"></div>
                  <div className="text">
                    <strong className="font_mont">3rd Quarter 2025</strong>
                    <ul>
                      <li>{t("2025-3Q-1")}</li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="box"
                  data-aos="fade-right"
                  data-aos-delay="650"
                  data-aos-duration="1000"
                >
                  <div className="img second"></div>
                  <div className="text">
                    <strong className="font_mont">4th Quater 2025</strong>
                    <ul>
                      <li>{t("2025-4Q-1")}</li>
                      <li>{t("2025-4Q-2")}</li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="load_map_box mobile" data-aos="fade">
          <div className="inner d-flex flex-jc">
            <div
              className="box"
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              <div className="img first"></div>
              <div className="text">
                <strong className="font_mont">3rd Quarter 2024</strong>
                <ul>
                  <li>{t("2024-3Q-1")}</li>
                  <li>
                    <Trans
                      i18nKey="2024-3Q-2"
                      components={{
                        1: <br />,
                      }}
                    />
                  </li>
                  <li>{t("2024-3Q-3")}</li>
                  <li>{t("2024-3Q-4")}</li>
                  <li>{t("2024-3Q-5")}</li>
                </ul>
              </div>
            </div>
            <div
              className="box"
              data-aos="fade-right"
              data-aos-delay="350"
              data-aos-duration="1000"
            >
              <div className="img second"></div>
              <div className="text">
                <strong className="font_mont">4th Quarter 2024</strong>
                <ul>
                  <li>{t("2024-4Q-1")}</li>
                  <li>{t("2024-4Q-2")}</li>
                  <li>{t("2024-4Q-3")}</li>
                  <li>{t("2024-4Q-4")}</li>
                  <li>{t("2024-4Q-5")}</li>
                </ul>
              </div>
            </div>
            <div
              className="box"
              data-aos="fade-right"
              data-aos-delay="500"
              data-aos-duration="1000"
            >
              <div className="img third"></div>
              <div className="text">
                <strong className="font_mont">1st Quarter 2025</strong>
                <ul>
                  <li>{t("2025-1Q-1")}</li>
                  <li>{t("2025-1Q-2")}</li>
                  <li>{t("2025-1Q-3")}</li>
                </ul>
              </div>
            </div>
            <div
              className="box"
              data-aos="fade-right"
              data-aos-delay="650"
              data-aos-duration="1000"
            >
              <div className="img fourth"></div>
              <div className="text">
                <strong className="font_mont">2nd Quarter 2025</strong>
                <ul>
                  <li>{t("2025-2Q-1")}</li>
                  <li>{t("2025-2Q-2")}</li>
                  <li>{t("2025-2Q-3")}</li>
                </ul>
              </div>
            </div>
            <div
              className="box"
              data-aos="fade-right"
              data-aos-delay="650"
              data-aos-duration="1000"
            >
              <div className="img first"></div>
              <div className="text">
                <strong className="font_mont">3rd Quarter 2025</strong>
                <ul>
                  <li>{t("2025-3Q-1")}</li>
                </ul>
              </div>
            </div>
            <div
              className="box"
              data-aos="fade-right"
              data-aos-delay="650"
              data-aos-duration="1000"
            >
              <div className="img second"></div>
              <div className="text">
                <strong className="font_mont">4th Quater 2025</strong>
                <ul>
                  <li>{t("2025-4Q-1")}</li>
                  <li>{t("2025-4Q-2")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}
