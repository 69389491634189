import { Routes, Route } from "react-router-dom";
import Main from "./components/page/Main";
// import WalletDetail from "./components/subPage/WalletDetail";
// import SolutionDetail from "./components/subPage/SolutionDetail";
// import NewsDetail from "./components/subPage/NewsDetail";
import NotFound from "./components/NotFound";
import MainLayout from "./components/MainLayout";
// css
import "./assets/css/scss/style.css";

window.__REACT_DEVTOOLS_GLOBAL_HOOK__ = { isDisabled: true };
function App() {
  return (
    <>
      <div className="contents">
        <Routes>
          <Route element={<MainLayout />}>
            {/* 일반 유저 */}
            <Route path="/" exact element={<Main />}></Route>
            {/* <Route path="/WalletDetail" element={<WalletDetail />}></Route>
            <Route path="/SolutionDetail" element={<SolutionDetail />}></Route>
            <Route path="/NewsDetail" element={<NewsDetail />}></Route> */}
          </Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </div>
      {/* {loading?<Loading /> : ""} */}
    </>
  );
}

export default App;
