
import { Outlet } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import AfterEffect from './AfterEffect';
import { RecoilRoot } from 'recoil';

const MainLayout = () => {
    return (
      <>
        <AfterEffect />
        <Header />
          <RecoilRoot>
            <Outlet />
          </RecoilRoot>
        <Footer />
      </>
    );
  };
  
export default MainLayout;